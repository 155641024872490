import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ReactComponent as QuestionIcon } from "../assets/svg/question.svg";

export const CustomInputComponent = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const isInvalid = touched[field.name] && errors[field.name];
    return (
        <div className="form-group">
            {props.label && <label htmlFor={field.name}>{props.label}</label>}
            <input
                type="text"
                className={`form-control` + (isInvalid ? ` is-invalid` : ``)}
                {...field}
                {...props}
            />
            {isInvalid && (
                <div className="invalid-feedback">{errors[field.name]}</div>
            )}
        </div>
    );
};

export class Spinner extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            shouldRender: true,
            show: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.show !== true) {
            this.setState({
                shouldRender: false,
                show: false,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show && !this.props.show) {
            this.setState({ show: false });
            setTimeout(() => {
                if (this._isMounted) {
                    this.setState({ shouldRender: false });
                }
            }, 1000);
        } else if (!prevProps.show && this.props.show) {
            this.setState({ shouldRender: true, show: true });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return this.state.shouldRender ? (
            <div className={`spinner` + (this.state.show ? " show" : "")}>
                <svg
                    width="75"
                    height="75"
                    mlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    className="lds-ellipsis"
                >
                    <circle cx="84" cy="50" r="0">
                        <animate
                            attributeName="r"
                            values="10;0;0;0;0"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="0s"
                        ></animate>
                        <animate
                            attributeName="cx"
                            values="84;84;84;84;84"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="0s"
                        ></animate>
                    </circle>
                    <circle cx="23.4861" cy="50" r="10">
                        <animate
                            attributeName="r"
                            values="0;10;10;10;0"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="-1s"
                        ></animate>
                        <animate
                            attributeName="cx"
                            values="16;16;50;84;84"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="-1s"
                        ></animate>
                    </circle>
                    <circle cx="16" cy="50" r="2.20179">
                        <animate
                            attributeName="r"
                            values="0;10;10;10;0"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="-0.5s"
                        ></animate>
                        <animate
                            attributeName="cx"
                            values="16;16;50;84;84"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="-0.5s"
                        ></animate>
                    </circle>
                    <circle cx="84" cy="50" r="7.79821">
                        <animate
                            attributeName="r"
                            values="0;10;10;10;0"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="0s"
                        ></animate>
                        <animate
                            attributeName="cx"
                            values="16;16;50;84;84"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="0s"
                        ></animate>
                    </circle>
                    <circle cx="57.4861" cy="50" r="10">
                        <animate
                            attributeName="r"
                            values="0;0;10;10;10"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="0s"
                        ></animate>
                        <animate
                            attributeName="cx"
                            values="16;16;16;50;84"
                            keyTimes="0;0.25;0.5;0.75;1"
                            keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                            calcMode="spline"
                            dur="2s"
                            repeatCount="indefinite"
                            begin="0s"
                        ></animate>
                    </circle>
                </svg>
            </div>
        ) : null;
    }
}

export const QuestionToolTip = (props) => {

    return (
        <OverlayTrigger
            {...props}
            overlay={
                <Tooltip>
                    {props.content}
                </Tooltip>
            }
        >
            <span className="tooltip-help ml-1"><QuestionIcon/></span>
        </OverlayTrigger>
    );
};

