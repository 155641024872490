import React, { Component } from "react";

export default class footer extends Component {
	render() {
		return (
			<footer className="app-footer">
				<div className="bg-primary py-4 text-md-center">
					<div className="container">
						<div className="pb-2">
							Copyright © {new Date().getFullYear()} Thomas Smith & Co Ltd.All rights
							reserved.<span className="mx-2">|</span>
							Developed by{" "}
							<a href="http://www.hive.com.mt" target="_blank" rel="noreferrer">
								The Hive
							</a>
						</div>
						<div>
							Thomas Smith Insurance Agency Ltd., is authorised to act as an Insurance
							Agent of GasanMamo Insurance Ltd., and is regulated by the Malta
							Financial Services Authority.
						</div>
					</div>
				</div>
				<div className="bg-dark px-2 py-4">
					<div className="container">
						<div className="row">
							<div className="col-sm-6 col-md-3">
								<h5>The Company</h5>
								<ul className="list-unstyled">
									<li>
										<a href="http://www.tcsmith.com/news/">Latest News</a>
									</li>
									<li>
										<a href="http://www.tcsmith.com/careers/">Careers</a>
									</li>
								</ul>
							</div>
							<div className="col-sm-6 col-md-3">
								<h5>The Company</h5>
								<ul className="list-unstyled">
									<li>
										<a href="https://www.tcsmithinsurance.com/contact-us/insurance-claims/">
											Insurance Claims
										</a>
									</li>
									<li>
										<a href="https://www.tcsmithinsurance.com/contact-us/customer-complaints/">
											Customer Complaints
										</a>
									</li>
								</ul>
							</div>
							<div className="col-sm-6 col-md-3">
								<h5>Legal</h5>
								<ul className="list-unstyled">
									<li>
										<a href="https://www.tcsmithinsurance.com/disclaimer/">
											Disclaimer
										</a>
									</li>
									<li>
										<a href="https://www.tcsmithinsurance.com/terms-of-use/">
											Terms of Use
										</a>
									</li>
									<li>
										<a href="https://www.tcsmithinsurance.com/copyright/">
											Copyright
										</a>
									</li>
									<li>
										<a href="https://www.tcsmithinsurance.com/privacy-policy/">
											Privacy Policy
										</a>
									</li>
									<li>
										<a href="https://www.tcsmith.com/cctv-privacy-notice/">
											CCTV Privacy Notice
										</a>
									</li>
									<li>
										<a href="https://www.tcsmith.com/about-us/data-protection-policy/">
											Personal Data Protection Policy
										</a>
									</li>
									<li>
										<a href="https://www.tcsmithinsurance.com/standard-trading-conditions/">
											Standard Trading Conditions
										</a>
									</li>
								</ul>
							</div>
							<div className="col-sm-6 col-md-3">
								<h5>Head Office</h5>
								<p>
									1, War Victims Square,
									<br />
									Luqa LQA1010,
									<br />
									Malta
								</p>
								<p>+356 2205 8000</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
