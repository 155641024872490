import React, { Component } from "react";
import Steps from "./steps";
import logo from "../assets/img/logo.png";

export class Header extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<header className="app-header">
				<img src={logo} className="logo img-fluid" alt="Logo" />
				<Steps steps={this.props.steps} step={this.props.step} />
			</header>
		);
	}
}

export default Header;
