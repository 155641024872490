import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import { Context } from "../state";
import { Summary } from "./Summary";

const Step3 = ({ history }) => {

	const [state, dispatch] = useContext(Context);
	const data = state.data;
	const premium = (parseInt(data.PriceSummary.Premium) / 100).toFixed(2);
	const duty = (parseInt(data.PriceSummary.Duty) / 100).toFixed(2);
	const policyFee = (parseInt(data.PriceSummary.PolicyFee) / 100).toFixed(2);

	data.PriceSummary.total =
		parseInt(data.PriceSummary.Premium) +
		parseInt(data.PriceSummary.Duty) +
		parseInt(data.PriceSummary.PolicyFee);
	const total = (parseInt(data.PriceSummary.total) / 100).toFixed(2);

	const goBack = () => {
		dispatch({ type: "SET_STEP", payload: 2 });
		history.push("/car/renewal/2");
	};

	return (
		<Fade duration="500">
			<div className="card step3">
				<div className="row no-gutters">
					<div className="col-md-5 d-none d-md-block">
						<Summary data={data} />
					</div>
					<div className="col-md-7">
						<div className="card-content">
							<h1 className="h3">Price summary</h1>
							<div className="list-group list-group-flush price-summary">
								<div className="list-group-item">
									<span>Premium</span>
									<span>€{premium}</span>
								</div>
								<div className="list-group-item">
									<span>
										Roadside Assistance
									</span>
									<span>FREE</span>
								</div>
								<div className="list-group-item">
									<span>Duty</span>
									<span>€{duty}</span>
								</div>
								<div className="list-group-item">
									<span>Policy Fee</span>
									<span>€{policyFee}</span>
								</div>
								<div className="list-group-item">
									<span className="h5 mb-0">Total</span>
									<span className="h5 mb-0">€{total}</span>
								</div>
							</div>
							
							<form action={process.env.REACT_APP_PAYMENT_URL} method="post">
			                	<input type="hidden" name="crf" value={data.PriceSummary.CRF} />
			                	<input type="hidden" name="reference" value={data.PriceSummary.Reference} />
			                	<input type="hidden" name="cipheramount" value={data.PriceSummary.cipheramount} />
			                	<input type="hidden" name="cipherkey" value={data.PriceSummary.cipherkey} />
			                	<div className="form-footer">
			                		<button className="btn btn-outline-primary" onClick={goBack}>Back</button>
			                		<button className="btn btn-primary" type="submit">Proceed to Payment →</button>
			                	</div>
							</form>
							<hr className="mt-4"/>
							<p className="small">When clicking on 'Proceed to Payment' you will be leaving this website and you will be redirected to our payment processing supplier.</p>
						</div>
					</div>
				</div>
			</div>
		</Fade>
	);
};

export default withRouter(Step3);
