import React from "react";
export const Summary = ({ data }) => {

	return (
		<div className="card-sidebar">
			<div className="small text-bold mb-0">MOTOR POLICY</div>
			<h4>{data.PolicyNo}</h4>
			<dl>
				<dt>Insured</dt>
				<dd>
					{data.Name}
					{data.IDNo && <div>{data.IDNo}</div>}
					{data.Email && <div>{data.Email}</div>}
				</dd>
				<dt>Insurance Period</dt>
				<dd>
					{data.InsuranceFrom} to {data.InsuranceTo}
				</dd>
				<dt>Cover Type</dt>
				<dd>{data.Cover}</dd>
				<dt>Engine Number</dt>
				<dd>{data.EngineNo}</dd>
				<dt>Make and Model</dt>
				<dd>{data.MakeModel}</dd>
				<dt>Registration Number</dt>
				<dd>{data.RegNo}</dd>
				{data.VehicleValue.VehicleValueAmount !== "0" &&(<dt>Vehicle Value</dt>)}
				{data.VehicleValue.VehicleValueAmount !== "0" &&(<dd>{new Intl.NumberFormat('en-MT', { style: 'currency', currency: 'EUR' }).format(data.VehicleValue.VehicleValueAmount/100)}</dd>)}
			</dl>
		</div>
	);
};
