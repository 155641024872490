import React, { useContext, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Context } from "../state";
import { Formik, Field, Form } from "formik";
import { Fade } from "react-awesome-reveal";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { CustomInputComponent } from "../../../components/common";
import * as Yup from "yup";

import { MotorRenewLogin } from "../helpers";

const validationSchema = Yup.object().shape({
	rPolicyNo: Yup.string().required("Policy Number is required"),
	rWebCode: Yup.string().required("Web Code is required"),
});


const Step1 = ({ history, step }) => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [state, dispatch, setLoading] = useContext(Context);

	const handleSubmit = useCallback(
		async (values, { setSubmitting }) => {
			dispatch({
				type: "SET_ERROR",
				payload: "",
			});

			if (!executeRecaptcha) {
				return;
			}

			values.token = await executeRecaptcha("MotorRenewLogin");

			setLoading(true);

			MotorRenewLogin(values, dispatch)
				.then((response) => {
					setLoading(false);
					history.push("/car/renewal/2");
				})
				.catch((error) => {
					setLoading(false);
					setSubmitting(false);
				});
		},
		[executeRecaptcha]
	);

	const initialValues = state.data;

	return (
		<Fade duration="500">
			<div className="card card-sm step1">
				<h1 className="h3">Motor Insurance Renewal</h1>
				<p>Please enter your Policy number and Web code to retrieve your policy.</p>
				<p>These can be found on your renewal notice.</p>

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						/* and other goodies */
					}) => (
						<Form>
							<Field
								label="Policy Number"
								name="rPolicyNo"
								component={CustomInputComponent}
							/>
							<Field
								label="Web Code"
								name="rWebCode"
								component={CustomInputComponent}
							/>

							{state.error && (
								<div className="alert alert-danger mt-4">{state.error}</div>
							)}
							<div className="form-footer">
								<button
									type="submit"
									className="btn btn-primary"
									disabled={isSubmitting}
								>
									Continue
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Fade>
	);
};

export default withRouter(Step1);
