import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import CarRenewal from "./pages/Car/Renewal";
import NotFound from "./pages/NotFound";
// import { Home } from "./pages/Home";
// import { Travel } from "./pages/Travel";

/*
When Quote or other forms are developed.
Change structure to pages/Car/Renewal/***
 */

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/car/renewal/:step" component={CarRenewal} />
        <Route exact path="/car/renewal/" component={CarRenewal} />
        <Redirect exact from="/car" to="/car/renewal/" />
        <Redirect exact from="/" to="/car/renewal/" />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}
