import axios from "axios";
import qs from "qs";

export function MotorRenewLogin(values, dispatch){

		const success = (response) => {
			dispatch({ type: "SET_DATA", payload: response.data });
			dispatch({ type: "ADD_DATA", payload: { PolicyNo: values.rPolicyNo } });
			dispatch({ type: "SET_STEP", payload: 2 });
			dispatch({ type: "SET_AUTHENTICATED", payload: true });
			return response;
		};

		const error = (error) => {
			if (error.response) {
				dispatch({ type: "SET_ERROR", payload: error.response.data });
			}
			return Promise.reject(error);
		};

		values.action = "MotorRenewLogin";

		return axios.post(`${process.env.REACT_APP_API_URL}motor.php`, qs.stringify(values)).then(success).catch(error);
}