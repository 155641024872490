import React, { useEffect, useReducer, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Reducer, initialState, Context } from "./state";
import queryString from "query-string";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Spinner } from "../../components/common";
import Header from "../../components/header";
import Footer from "../../components/footer";

import { MotorRenewLogin } from "./helpers";

import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
// import Step4 from "./components/Step4";
// import Step5 from "./components/Step5";

export default ({ history, match, location }) => {
	const [state, dispatch] = useReducer(Reducer, initialState);

	const steps = {
		1: Step1,
		2: Step2,
		3: Step3,
		// 4: Step4,
		// 5: Step5
	};

	const stepNum = match.params.step || 1;
	const StepComponent = steps[match.params.step] ? steps[match.params.step] : Step1;

	const values = queryString.parse(location.search);

	const isLoading = state.isLoading;

	const setLoading = (t) => {
		if (t) {
			dispatch({ type: "SET_LOADING", payload: true });
		} else {
			setTimeout(() => {
				dispatch({ type: "SET_LOADING", payload: false });
			}, 1000);
		}
	};

	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			return;
		}



		const data = {
			rPolicyNo: values.policy_number,
			rWebCode: values.web_code,
		};

		dispatch({
			type: "ADD_DATA",
			payload: data,
		});

		data.token = await executeRecaptcha("MotorRenewLogin");

		MotorRenewLogin(data, dispatch)
			.then((response) => {
				setLoading(false);
				history.push("/car/renewal/2");
			})
			.catch((error) => {
				setLoading(false);
			});
	}, [executeRecaptcha]);

	useEffect(() => {
		if (stepNum > 1 && !state.authenticated) {
			dispatch({ type: "SET_STEP", payload: 1 });
			dispatch({ type: "SET_AUTHENTICATED", payload: false });
			history.push("/car/renewal/1");
		}

		if (values.policy_number && values.web_code) {
			setLoading(true);
			handleReCaptchaVerify();
		}
	}, [handleReCaptchaVerify]);

	return (
		<Context.Provider value={[state, dispatch, setLoading]}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Thomas Smith Insurance Agency</title>
				<meta name="description" content="Motor Insurance Renewal" />
			</Helmet>
			<Header steps="4" step={stepNum} />
			<main className="app-main">
				<div className="container">
					<Spinner show={isLoading} />
					<StepComponent />
					{/*{state.step === 1 && <Step1 />}
					{state.step === 2 && <Step2 />}
					{state.step === 3 && <Step3 />}
					{state.step === 4 && <Step4 />}
					{state.step === 5 && <Step5 />}*/}
				</div>
			</main>
			<Footer />
		</Context.Provider>
	);
};
