import React from "react";
import { ReactComponent as Icon } from "../assets/svg/step.svg";

const Steps = ({ steps, step }) => {
	
	const items = [];
	
	for (let i = 0; i < steps; i++) {
		const n = i+1;
		if (n>1) {
			items.push(<div key={`sep${n}`} className="item-sep"><span></span></div>);
		}
		if (n===parseInt(step)) {
			items.push(<div key={`step${n}`} className="item-step active"><span>{n}</span></div>);
		}else if(n<step){
			items.push(<div key={`step${n}`} className="item-icon"><Icon className="icon"/></div>);
		}else{
			items.push(<div key={`step${n}`} className="item-step"><span>{n}</span></div>);	
		}
	}

	return <div className="steps">{items}</div>;
};


export default Steps;