import { createContext } from "react";

export const Reducer = (state, action) => {
    switch (action.type) {
        case "SET_STEP":
            return {
                ...state,
                step: action.payload,
            };
        case "SET_DATA":
            return {
                ...state,
                data: action.payload,
            };
        case "SET_AUTHENTICATED":
            return {
                ...state,
                authenticated: action.payload,
            };
        case "SET_LOADING":
            return {
                ...state,
                isLoading: action.payload,
            };
        case "ADD_DATA":
            return {
                ...state,
                data: Object.assign(state.data, action.payload),
            };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const initialState = {
    step: 1,
    isLoading: false,
    authenticated: false,
    data: {
        Address1: "",
        Address2: "",
        Address3: "",
        AuthorisedDrivers: {
            Driver: "",
        },
        Country: "",
        Cover: "",
        Email: "",
        EngineNo: "",
        IDNo: "",
        InsuranceFrom: "",
        InsuranceTo: "",
        LicenceAmount: "",
        MakeModel: "",
        Name: "",
        Payment: {
            success: true,
        },
        PolicyNo: "",
        PostCodeID: "",
        PriceSummary: {},
        PurchaseProtection: {
            ShowPurchaseProtection: "",
            PurchaseProtectionWording: "",
            PurchaseProtectionPrice: "",
            PurchaseProtectionSelected: "",
            PurchaseProtectionMessage: "",
        },
        RegNo: "",
        RenewalValue: {
            HelpMessageParagraph1: "",
            HelpMessageParagraph2: "",
            MarketValue: 0,
        },
        Restrictions: {
            VehicleValue: {
                CurrencyMax: "",
                CurrencyMin: "",
            },
        },
        RoadsideAssistance: {
            ShowRoadsideAssistance: "",
            RoadsideAssistanceWording: "",
            RoadsideAssistancePrice: "",
            RoadsideAssistanceSelected: "",
        },
        Town: "",
        VRTDueDate: "",
        VehicleValue: {
            VehicleValueAmount: "",
            VehicleValueWording: "",
        },
        rPolicyNo: "",
        rWebCode: "",
    },
    error: null,
};

export const Context = createContext(initialState);
