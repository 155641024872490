import React, { useContext, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import qs from "qs";
import { Formik, Field, Form } from "formik";
import { Fade } from "react-awesome-reveal";
import { Context } from "../state";
import {
	CustomInputComponent,
	QuestionToolTip,
} from "../../../components/common";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Summary } from "./Summary";

const Step2 = ({ history }) => {
	const [state, dispatch, setLoading] = useContext(Context);
	const data = state.data;
	const { CurrencyMin, CurrencyMax } = data.Restrictions.VehicleValue;
	const PolicyNo = data.PolicyNo;
	const minValue = Math.max(1000, CurrencyMin) / 100;
	const maxValue = Math.max(1000, CurrencyMax) / 100;
	const isMotorcycle = data.Cover.startsWith("Motorcycle");

	const initialValues = {
		rEmail: "",
		rVehValue: data.VehicleValue.VehicleValueAmount / 100,
		rRSA: true,
		rPurchaseProtected: parseInt(
			data.PurchaseProtection.PurchaseProtectionSelected
		),
		rRoadsideAssistance: parseInt(
			data.RoadsideAssistance.RoadsideAssistanceSelected
		),
	};

	const enablePurchaseProtection = !initialValues.rPurchaseProtected;

	if (data.Email) {
		initialValues.rEmail = data.Email;
	}

	if (data.RenewalValue) {
		initialValues.rVehValue =
			Math.max(
				data.VehicleValue.VehicleValueAmount,
				data.RenewalValue.MarketValue
			) / 100;
	}

	if (data.rVehValue) {
		initialValues.rVehValue = data.rVehValue / 100;
	}

	const hasVehValue = initialValues.rVehValue > 0;
	let validationSchema = Yup.object().shape({
		// rPolicyNo: Yup.string().required("Policy Number is required"),
		rEmail: Yup.string()
			.email("Enter a valid Email address")
			.required("Email is required"),
	});

	if (hasVehValue) {
		validationSchema = validationSchema.shape({
			rVehValue: Yup.number()
				.min(
					minValue,
					`The Vehicle Value must be greater than or equal to ${minValue}`
				)
				.max(maxValue, `The Vehicle Value must not exceed ${maxValue}`)
				.required("Vehicle Value is required"),
		});
	}

	const showPurchaseProtection =
		data.PurchaseProtection.ShowPurchaseProtection === "1";
	const purchaseProtectionPrice = (
		parseInt(data.PurchaseProtection.PurchaseProtectionPrice) / 100
	).toFixed(2);

	// Courtesy Car
	const courtesyCarApplies = parseInt(data.CourtesyCarApplies) !== 0;
	const selectedCourtesyCarOption = parseInt(data.CourtesyCarSelectedOption);
	const isSelectableCourtesyCar = selectedCourtesyCarOption > 0;
	const courtesyCarOptions =
		!isMotorcycle &&
		courtesyCarApplies &&
		data.CourtesyCarOptions &&
		"CourtesyCarOption" in data.CourtesyCarOptions
			? data.CourtesyCarOptions["CourtesyCarOption"].filter((o) => {
					const option = parseInt(o.CourtesyCarOption);
					return isSelectableCourtesyCar
						? option == selectedCourtesyCarOption
						: option != selectedCourtesyCarOption;
			  })
			: [];

	initialValues.rCourtesyCar = selectedCourtesyCarOption;

	//update initital values if populated from a back event
	if ("rPurchaseProtected" in data) {
		initialValues.rPurchaseProtected = data.rPurchaseProtected;
	}

	if (data.rRoadsideAssistance) {
		initialValues.rRoadsideAssistance = data.rRoadsideAssistance;
	}
	if (data.rEmail) {
		initialValues.rEmail = data.rEmail;
	}
	if (data.rCourtesyCar) {
		initialValues.rCourtesyCar = data.rCourtesyCar;
	}

	//recaptcha

	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleReCaptchaVerify = useCallback(
		async (PolicyNo, dispatch) => {
			if (!executeRecaptcha) {
				return;
			}

			const token = await executeRecaptcha("MotorGetRenewalValue");

			axios
				.post(
					`${process.env.REACT_APP_API_URL}motor.php`,
					qs.stringify({
						action: "MotorGetRenewalValue",
						rPolicyNo: PolicyNo,
						token,
					})
				)
				.then((response) => {
					dispatch({
						type: "ADD_DATA",
						payload: { RenewalValue: response.data },
					});
				})
				.catch((error) => {
					dispatch({ type: "SET_STEP", payload: 1 });
					dispatch({ type: "SET_AUTHENTICATED", payload: false });
					history.push("/car/renewal/1");
				});
		},
		[executeRecaptcha]
	);

	useEffect(() => {
		handleReCaptchaVerify(PolicyNo, dispatch);
	}, [handleReCaptchaVerify]);

	/*	useEffect(() => {
		axios
			.post(
				`${process.env.REACT_APP_API_URL}motor.php`,
				qs.stringify({
					action: "MotorGetRenewalValue",
					rPolicyNo: PolicyNo,
				})
			)
			.then((response) => {
				dispatch({
					type: "ADD_DATA",
					payload: { RenewalValue: response.data },
				});
			});
	}, [PolicyNo, dispatch]);*/

	const handleSubmit = (values, { setSubmitting }) => {
		setLoading(true);

		const success = (response) => {
			dispatch({
				type: "ADD_DATA",
				payload: {
					PriceSummary: response.data,
					...values,
				},
			});

			setLoading(false);
			dispatch({ type: "SET_STEP", payload: 3 });
			history.push("/car/renewal/3");
		};

		const error = (error) => {
			if (error.response) {
				dispatch({ type: "SET_ERROR", payload: error.response.data });
				setSubmitting(false);
			}
		};

		values.action = "MotorRenewPolicy";
		values.rPolicyNo = PolicyNo;
		values.rVehValue = values.rVehValue * 100;
		values.rRSA = true === values.rRSA ? "1" : "";

		axios
			.post(
				`${process.env.REACT_APP_API_URL}motor.php`,
				qs.stringify(values)
			)
			.then(success)
			.catch(error);
	};

	return (
		<Fade duration="500">
			<div className="card step2">
				<div className="row no-gutters">
					<div className="col-md-5">
						<Summary data={data} />
					</div>
					<div className="col-md-7">
						<div className="card-content">
							<h1 className="h3">Update your details</h1>
							<p>
								You can update the policy details before
								renewing.
							</p>

							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
									/* and other goodies */
								}) => (
									<Form>
										<Field
											label="Email Address"
											type="email"
											name="rEmail"
											component={CustomInputComponent}
										/>

										{hasVehValue && (
											<Field
												label="Current Sum Insured"
												type="number"
												name="rVehValue"
												component={CustomInputComponent}
											/>
										)}

										<div className="form-group pt-2">
											<div className="form-check my-2">
												<Field
													className="form-check-input"
													type="checkbox"
													name="rRSA"
													id="rRSA"
													checked={
														values.rRoadsideAssistance
													}
													disabled={
														values.rRoadsideAssistance
													}
												/>
												<label
													className="form-check-label text-body ml-2"
													htmlFor="rRSA"
												>
													Roadside Assistance - <b>FREE</b>
													<QuestionToolTip
																placement="right"
																delay={{
																	show: 250,
																	hide: 400,
																}}
																content={`Kindly refer to our renewal invitation`}
															/>
												</label>
											</div>

											{!isMotorcycle &&
												showPurchaseProtection && (
													<div className="form-check my-2">
														<Field
															className="form-check-input"
															type="checkbox"
															name="rPurchaseProtected"
															id="rPurchaseProtected"
															onChange={(event) =>
																setFieldValue(
																	"rPurchaseProtected",
																	event.target
																		.checked
																		? 1
																		: 0
																)
															}
															checked={
																values.rPurchaseProtected ===
																1
															}
															disabled={
																!enablePurchaseProtection
															}
														/>
														<label
															className="form-check-label text-body ml-2"
															htmlFor="rPurchaseProtected"
														>
															No Claim Discount
															Protection -{" "}
															<b>
																€
																{
																	purchaseProtectionPrice
																}
															</b>
															<QuestionToolTip
																placement="right"
																delay={{
																	show: 250,
																	hide: 400,
																}}
																content={`For an extra €${purchaseProtectionPrice} you can protect your No Claim discount and with one claim the discount will not be reduced.`}
															/>
														</label>
													</div>
												)}

											{!isMotorcycle &&
												courtesyCarApplies &&
												courtesyCarOptions.map((i) => {
													const id = `CourtesyCarOption${i.CourtesyCarOption}`.toString();
													const price = parseInt(
														i.CourtesyCarPrice
													).toFixed(2);
													const option = parseInt(
														i.CourtesyCarOption
													);
													return (
														<div
															className="form-check my-2"
															key={id}
														>
															<Field
																className="form-check-input"
																type="checkbox"
																name={id}
																id={id}
																checked={
																	values.rCourtesyCar ===
																	option
																}
																disabled={
																	isSelectableCourtesyCar
																}
																onChange={(
																	event
																) => {
																	setFieldValue(
																		"rCourtesyCar",
																		event
																			.target
																			.checked
																			? option
																			: 0
																	);
																}}
															/>
															<label
																className="form-check-label text-body ml-2"
																htmlFor={id}
															>
																Courtesy Car{" "}
																{
																	i.CourtesyCarTitle
																}{" "}
																(Limit €
																{
																	i.CourtesyCarLimit
																}
																) -{" "}
																<b>€{price}</b>
																<QuestionToolTip
																	placement="right"
																	delay={{
																		show: 250,
																		hide: 400,
																	}}
																	content={`For an extra €${price} you can have the benefit to cover the hiring of an alternative car while your car is being repaired following an accident, fire or theft.`}
																/>
															</label>
														</div>
													);
												})}
										</div>

										<div className="form-footer">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isSubmitting}
											>
												Continue
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</Fade>
	);
};

export default withRouter(Step2);
