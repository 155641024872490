import { Helmet } from "react-helmet";
import logo from "../assets/img/logo.png";

export default () => (
	<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Thomas Smith Insurance Agency</title>
			<meta name="description" content="Not Found" />
		</Helmet>
		<main className="app-main p-5 justify-content-center d-flex align-items-center text-center">
			<div>
				<img src={logo} className="logo img-fluid mb-5" alt="Logo" />
				<h3>Page not found</h3>
				<p><a href="https://www.tcsmithinsurance.com/">Back to home</a></p>
			</div>
		</main>
	</>
);
